import { theme } from '../theme'

type Props = {
  color?: keyof typeof theme.colors
  size?: string
}

export const BankIdIcon = ({ color, size = '1.125rem' }: Props) => {
  const fillColor = color ? theme.colors[color] : 'currentColor'

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.69897 12.5332L8.68296 6.34118H7.6325C7.14143 6.34118 6.5082 6.0661 6.32358 5.56026C6.26266 5.38856 6.12235 4.79964 6.93281 4.22733C7.22266 4.0261 7.40912 3.80272 7.44604 3.63103C7.48481 3.4538 7.43866 3.29687 7.31312 3.17872C7.13404 3.00703 6.78512 2.90918 6.33835 2.90918C5.58696 2.90918 5.05712 3.34118 5.00543 3.65503C4.9685 3.8858 5.14758 4.07041 5.30266 4.19041C5.77158 4.53933 5.8842 5.04518 5.59435 5.52333C5.29527 6.01441 4.64912 6.33379 3.95681 6.34118H2.88419C2.79558 6.94487 1.33527 16.1886 1.2245 16.9178H7.03804C7.08973 16.5892 7.35743 14.8409 7.72296 12.5332H7.69897Z"
          fill={fillColor}
        />
        <path
          d="M15.4844 0.922852H9.56562L8.77731 5.93147H9.78162C10.3336 5.93147 10.8542 5.67854 11.0776 5.31485C11.1515 5.19485 11.181 5.09147 11.181 4.99362C11.181 4.78501 11.0407 4.62993 10.8985 4.5247C10.5109 4.23485 10.4296 3.93024 10.4296 3.71424C10.4296 3.66993 10.4296 3.63116 10.437 3.59424C10.5182 3.06624 11.2327 2.49393 12.1779 2.49393C12.7447 2.49393 13.1767 2.62685 13.437 2.87239C13.6678 3.08839 13.7564 3.39301 13.6899 3.71424C13.6087 4.0927 13.2284 4.40654 13.0124 4.56162C12.4401 4.96408 12.5139 5.31485 12.5509 5.41824C12.6709 5.73024 13.125 5.93147 13.4739 5.93147H14.9989V5.93885C17.0832 5.95362 18.2001 6.91362 17.8512 9.14747C17.5225 11.2244 15.9293 12.1161 14.0315 12.1327L13.2801 16.9253H14.3896C19.0715 16.9253 22.8967 13.9179 23.6481 9.16962C24.5785 3.27485 20.8419 0.922852 15.4844 0.922852Z"
          fill={fillColor}
        />
        <path
          d="M0.91149 19.0546H3.28564C4.29734 19.0546 4.54287 19.5679 4.46903 20.0368C4.40995 20.4171 4.1478 20.6995 3.70287 20.886C4.2678 21.102 4.49118 21.4362 4.40995 21.9642C4.30472 22.6269 3.73241 23.118 2.97918 23.118H0.278259L0.91149 19.0546ZM2.48072 20.7365C2.94226 20.7365 3.15826 20.4909 3.20257 20.2011C3.24872 19.8872 3.10657 19.6731 2.64503 19.6731H2.23518L2.07272 20.7365H2.48072ZM2.2278 22.494C2.70411 22.494 2.97918 22.3002 3.04749 21.9051C3.09918 21.5635 2.90534 21.3623 2.4438 21.3623H1.97487L1.7958 22.5014H2.2278V22.494Z"
          fill={fillColor}
        />
        <path
          d="M7.73759 23.1478C7.11913 23.1921 6.8219 23.1257 6.67236 22.858C6.34559 23.0592 5.98005 23.1626 5.59421 23.1626C4.89452 23.1626 4.64898 22.7989 4.71544 22.3964C4.74498 22.2026 4.85575 22.0161 5.03482 21.861C5.42252 21.525 6.37513 21.4807 6.74621 21.2278C6.77759 20.9453 6.66498 20.842 6.31605 20.842C5.90621 20.842 5.56282 20.9749 4.97575 21.3773L5.1179 20.4543C5.62375 20.0887 6.11482 19.9189 6.67975 19.9189C7.40159 19.9189 8.04221 20.2161 7.92221 21.0044L7.7819 21.898C7.73021 22.21 7.74498 22.3078 8.0939 22.3152L7.73759 23.1478ZM6.66498 21.7484C6.33821 21.957 5.72713 21.9201 5.66067 22.3521C5.63113 22.5533 5.75667 22.701 5.9579 22.701C6.15175 22.701 6.3899 22.6198 6.58375 22.485C6.56898 22.4112 6.57636 22.3373 6.59852 22.1952L6.66498 21.7484Z"
          fill={fillColor}
        />
        <path
          d="M8.89075 19.9706H10.1258L10.0575 20.3804C10.4526 20.0444 10.7498 19.9189 11.1375 19.9189C11.8298 19.9189 12.1492 20.3417 12.0384 21.034L11.7191 23.1109H10.4821L10.7498 21.3921C10.8034 21.0801 10.7055 20.9306 10.4674 20.9306C10.2735 20.9306 10.0963 21.034 9.9246 21.2647L9.64214 23.1035H8.40521L8.89075 19.9706Z"
          fill={fillColor}
        />
        <path
          d="M12.9993 19.0546H14.2344L13.9224 21.0485L15.1058 19.9703H16.6307L15.1132 21.3088L16.3335 23.1106H14.779L13.8412 21.6596H13.8246L13.603 23.1106H12.3661L12.9993 19.0546Z"
          fill={fillColor}
        />
        <path
          d="M17.3748 19.0546H18.7964L18.1705 23.1106H16.749L17.3748 19.0546Z"
          fill={fillColor}
        />
        <path
          d="M19.4887 19.0546H21.5194C23.0905 19.0546 23.5447 20.1937 23.3951 21.1389C23.253 22.062 22.5237 23.1106 21.1484 23.1106H18.8554L19.4887 19.0546ZM20.805 22.1432C21.4973 22.1432 21.8776 21.8017 21.9884 21.0799C22.0696 20.5426 21.9071 20.0146 21.141 20.0146H20.7607L20.4339 22.1432H20.805Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}
